@import 'styles/_sass-includes.scss';

.flyout-menu {
  position: absolute;
  top: 100%;
  text-align: left;
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: 100;
  background: $white;
  color: $black;
  box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.15);
  padding: 2rem 4.5rem 2rem 1.5rem;
  display: none;
  white-space: nowrap;

  &.active {
    display: block;
  }

  &.align-bottom {
    top: auto;
    bottom: 100%;
  }

  &.align-right {
    right: 0;
  }

  &.with-subcategories {
    padding: 2rem 1.5rem;
    width: 250px;
    max-height: 500px;
    overflow-y: auto;
  }

  .menu-item & {
    top: 5rem;
    left: 0;
  }

  .socialicons {
    margin-top: 15px;
  }
}

.flyout-menu-item {
  display: block;
  margin-bottom: 2.7rem;
  font-weight: 500;

  &:last-child {
    margin-bottom: 0rem;
  }

  a {
    text-decoration: none;
    color: $black;
    text-transform: capitalize;
    font-family: 'Uniform', sans-serif;
  }

  &.is-active {
    .is-active {
      font-weight: bold;
      text-decoration: underline;
    }
  }

  &.with-subcategories {
    font-weight: bold;
    margin-bottom: 24px;
  }
}

// CRE-513 ab test: showing subcategories on mobile
.flyout-menu-subitem {
  font-size: 14px;
  margin-top: 12px;
  font-weight: normal;

  &.is-active {
    font-weight: bold;
    text-decoration: underline;
  }

  a {
    color: $darkergrey;
  }
}

.flyout-menu .accordion__content > div {
  padding-bottom: 15px;
}

.flyout-menu .accordion__title {
  height: unset;
}
//---------

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger-button {
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; }
  .hamburger-button:hover {
    opacity: 0.7; }
  .hamburger-button.is-active:hover {
    opacity: 0.7; }
  .hamburger-button.is-active .hamburger-button-inner,
  .hamburger-button.is-active .hamburger-button-inner::before,
  .hamburger-button.is-active .hamburger-button-inner::after {
    background-color: inherit; }

.hamburger-button-box {
  width: 3rem;
  height: 1.3rem;
  display: inline-block;
  position: relative; }

.hamburger-button-inner {
  display: block;
  top: 50%;
  margin-top: -2px; }
  .hamburger-button-inner, .hamburger-button-inner::before, .hamburger-button-inner::after {
    width: 2.5rem;
    height: 0.2rem;
    background-color: inherit;
    border-radius: 0rem;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
  .hamburger-button-inner::before, .hamburger-button-inner::after {
    content: "";
    display: block; }
  .hamburger-button-inner::before {
    top: -0.5rem;
    background-color: inherit;
  }
  .hamburger-button-inner::after {
    bottom: -0.5rem;
    background-color: inherit;
  }

/*
   * Minus
   */
.hamburger-button--minus .hamburger-button-inner::before, .hamburger-button--minus .hamburger-button-inner::after {
  transition: bottom 0.08s 0s ease-out, top 0.08s 0s ease-out, opacity 0s linear; }

.hamburger-button--minus.is-active .hamburger-button-inner::before, .hamburger-button--minus.is-active .hamburger-button-inner::after {
  opacity: 0;
  transition: bottom 0.08s ease-out, top 0.08s ease-out, opacity 0s 0.08s linear; }

.hamburger-button--minus.is-active .hamburger-button-inner::before {
  top: 0; }

.hamburger-button--minus.is-active .hamburger-button-inner::after {
  bottom: 0; }
