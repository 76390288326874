@import 'styles/_sass-includes.scss';

.heading__block {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 800px;
    margin: auto;
    margin-bottom: 16px;
}

.heading__title h3{
    margin-top: -5px;
}

.heading__title {
    padding: 12px !important;
    background: #000;
    width: fit-content;
    margin-bottom: -12px;
    color: #fff;
    z-index: 1;
    display: flex;
    align-items: center;
    gap: 16px;
}

@media screen and (max-width: 768px) {
    .heading__title {
        flex-direction: column;
    }
}

.heading__content {
    padding: 32px 24px 24px 44px;
    background-color: $pdpRed;
    z-index: 0;
    font-size: 16px;
    text-align: center;
    color: #000;
    line-height: 118%;
    position: relative;
    min-width: 300px;
}

@media screen and (max-width: 768px) {
    .heading__content {
        max-width: 80%;
    }
}

.heading__content:before {
    content: '';
    position: absolute;
    top: -10px;
    left: -70px;
    width: 100px;
    height: 100px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: +99;
    background-image: var(--before-img);
}

.heading__content:after{
    content: '';
    position: absolute;
    top: -10px;
    right: -70px;
    width: 100px;
    height: 100px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: +99;
    background-image: var(--after-img);
}

.countdown__block {
    color: #fff;
}

.countdown__block--inner {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 8px;
    font-size: 12px;
}

.countdown__block--inner .digits {
    font-size: 14px;
    font-weight: 700;
}

.ended {
    font-size: 14px;
    font-weight: 700;
}