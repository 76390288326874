@import url("https://use.typekit.net/wzg8tjf.css");
@font-face {
    font-family: 'Uniform';
    font-style: normal;
    font-weight: 400;
    src: url(https://premium-storefronts.s3.amazonaws.com/storefronts/pewdiepiemerch/fonts/uniform-regular-webfont.woff2) format('woff2'),
    url(https://premium-storefronts.s3.amazonaws.com/storefronts/pewdiepiemerch/fonts/uniform-regular-webfont.woff) format('woff');
}

@font-face {
    font-family: 'Uniform';
    font-style: normal;
    font-weight: 500;
    src: url(https://premium-storefronts.s3.amazonaws.com/storefronts/pewdiepiemerch/fonts/uniform-medium-webfont.woff2) format('woff2'),
         url(https://premium-storefronts.s3.amazonaws.com/storefronts/pewdiepiemerch/fonts/uniform-medium-webfont.woff) format('woff');
}

:root {
    --body-font-family: 'Uniform', -apple-system, "Helvetica Neue", Roboto, Helvetica, Arial, sans-serif !important;
    --blue: #F9013C !important;
}

/* Dark theme overrides */

body {
    background-color: $pdpBlack;
    font-family: 'Uniform', sans-serif !important;
}

p {
    font-family: 'Uniform', sans-serif !important;
}

.universal.footer {
    background-color: $pdpBlack !important;
}

.tile-heading, .relatedgrid h3, .shopping-cart-heading, .cart-item-title a, .cart-summary-label, .cart-item-price .price, .product-detail-tabs__content, .color-label, .cart-item__controls .pr_inputfield input, .products_carousel h3 {
    color: $white;
}

.cart-item-info .cart-item-remove-btn {
    color: #acacac;
}

.listing-details p, .listing-details .form__label, .pr_tabs__item span, .cart-item-product-name, .typ--link, .cart-summary-note, .product-tile-title, .sizechart__modal-btn button, .theme--light .product-detail-tabs .product-detail-tabs__content, .cart-item-variation {
color: #BFBFBF;
}

.relatedgrid h3, .shopping-cart-heading, .listing-header__title, .products_carousel h3 {
    font-family: 'Piepie', sans-serif !important;
    text-transform: uppercase;
}

.hero__container {
    padding: 1.5rem 3em 0;
    max-width: 136.3rem;
    margin: 0 auto;
    width: 100%;
    @include breakpoint($mobile-lg) {
        padding: 0;
    }

}

.product-tile-details {
    color: $white;
}

.listing-details .listing-header__title, .listing-header__price span {
    color: $white !important;
}

.products_carousel_wrapper::after, .products_carousel_wrapper::before {
    display: none;
}

.listing-details {
    border: none;
}

.physical_return__details {
    background-color: rgba(255, 255, 255, 0.07);
    color: $white !important;
    border: none !important;
}

.physical_return__details strong, .physical_return__details a {
    color: $white !important;
}

.pr_tabs {
    border-top: initial !important;
    border-bottom: 1px solid rgba(217, 217, 217, 0.4) !important;
}

.pr_tabs__item.is-active span:after {
    background-color: $pdpRed !important;
}

.pr_tabs__item.is-active span {
    color: $white !important;
}

.product-detail-tabs .pr_tabs__item {
    margin-bottom: -4px;
}

.variantselector__grid.size .variantselector__item, .variantselector__grid.size {
    border-color: $pdpBlack;
}

.variantselector__grid.size .variantselector__item {
    background: rgba(255, 255, 255, 0.07);
    color: #fff;
    height: 48px;
}

.variantselector__item.is-active .variantselector__size {
    background-color: $white !important;
    color: $pdpBlack;
    font-weight: 600;
    height: 48px;
}

.pr_selectfield__toggle {
    background-color: hsla(0,0%,100%,.07) !important;
    border: 1px solid #3c3c3c !important;
    color: $white !important;
}

.checkoutsection .pr_selectfield__toggle {
    color: initial !important;
}

.checkoutsection .pr_selectfield__toggle:after {
    border-color: #000 !important;
}


div:not(.listing__addcart--mobile) .listing-order-form .pr_inputfield__label--animated,div:not(.listing__addcart--mobile) .singleselector__label, .cart-item__controls .pr_inputfield__label--animated {
    background-color: #262626 !important;
    border-radius: 8px;
    color: $white !important;
}

.listing-order-form .singleselector {
    color: #fff;
}

.pr_selectfield__toggle:after {
    border-color: $white !important;
}

.pr_modal__inner {
    .pr_selectfield__selected_item {
        color: #000 !important;
    }
    .pr_selectfield__toggle:after {
        border-color: #000 !important;
    }
}

/* PDP overrides */

.listing__options.sizes {
    .pr_inputfield__label {
        display: none;
    }
    @include breakpoint($mobile-lg) {
        .pr_inputfield__label {
            display: initial;
        }
    }
}

.listing-gallery-viewport, .listing-gallery-menu-item {
    border: none;
    border-radius: 0;
}

.product-tile-image > div {
    border-radius: inherit;
    border: none;
}

.product-tile-quick-add-to-cart-btn {
    display: none !important;
}

.report__wrapper {
    display: none;
}

.pr_btn {
    font-weight: 500 !important;
}

.pr_btn.btn--primary {
    background-color: $pdpRed;
    color: $white;
    border-color: $pdpRed;
    font-weight: 500;
}

.hero__inner {
    padding-bottom: 43% !important;
}

.footer__newsletter {
    text-align: center;
}

.footer__newsletter h5, .heading__block h3 {
    font-family: 'Piepie', sans-serif !important;
    text-transform: uppercase;
}

.footer__newsletter h5 {
    font-size: 32px !important;
}

.footer__inner {
    grid-template-areas: "menu newsletter about" !important;

    @include breakpoint($mobile-lg) {
        display: inline;
    }
}

.footer__inner input[type="email"] {
    background-color: rgba(255, 255, 255, 0.07);
    border-radius: 200px 0px 0px 200px;
    padding: 10px 18px;
    color: $white;
    width: 225px;
}

.footer__bottom__inner {
    align-items: bottom;

    @include breakpoint($mobile-lg) {
        align-items: center;
        text-align: center;
        display: flex;
        justify-content: center;
    }
}

#hsForm_fbf727bd-7036-4708-bf68-4d5c0042ed24{
    margin: auto;
}
.hs-button {
    background-color: $pdpRed !important;
    padding: 10px 18px;
    border-radius: 0px 200px 200px 0px;
    color: $white;
    width:  max-content;
}
.hs-button:hover {
    background-color: $pdpRed !important;
    opacity: 0.8;
}

.hs-error-msg {
    font-size: 12px;
}

.relatedgrid_container {
    display: none;
}

.relatedgrid__products {
    justify-content: initial;
}

.color .variantselector__item.is-active:after {
        top: -1px;
        left: 21%;
}

.header__hamburger {
    display: none !important;
}

.pr_modal__inner .submitted-message.hs-main-font-element p {
    color: #000;
}

.skeleton {
    opacity: .7;
    animation: dark-skeleton-loader 1s linear infinite alternate !important; 
}

.pr_btn.btn--primary:hover {
    background-color: $pdpRedDark !important;
    border-color: $pdpRedDark !important;
}

.product-tile-image {
    background-color: #373737 !important;
    transition: linear 0.2s;
}

.listing-gallery-viewport img, .listing-gallery-menu-item img {
    background-color: #373737 !important;
}

.cart-item-img {
    background-color: #373737 !important;
    border: none;
}

.listing-gallery-menu-item.is-active {
    border: solid 1px $pdpRed !important;
    border-radius: 8px;
}

.listing-gallery-menu-item {
    border: 1px solid #D9D9D9;
    border-radius: 8px;
}

.product-tile-image:hover {
    background-color: $pdpRed !important;
}
  
@keyframes dark-skeleton-loader {
    0% {
        background-color: #373737; 
    }
    100% {
        background-color: #242424;
    } 
}

.footer__localization span.mr2 {
    display: none;
}

.localizationcontrols {
    border: 1px solid #262626;
    padding: 10px 18px;
    border-radius: 16px;
}

.footer__localization button > span {
    display: contents;
    padding: 16px;
}

.footer__localization button {
    gap: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pr_modal__content {
    width: auto;
    min-width: 450px;
    @include breakpoint($mobile-lg) { 
        min-width: 100%;
    }
}
    
.cartconfirm__total {
    margin-left: -24px;
    margin-right: -24px;
    padding: 16px 24px;
    background: #f0f0f0;
    @include breakpoint($mobile-lg) {
        margin-bottom: 48px !important;
    }
}

.listing__addcart--mobile .singleselector {
    color: #000 !important;
}

.listing__addcart--mobile .pr_selectfield__selected_item {
    color: #000 !important;
}

.listing__addcart--mobile .pr_selectfield__toggle:before, .listing__addcart--mobile .pr_selectfield__toggle:after {
    border-color: #000 !important;
}

.listing__addcart--mobile .sizechart__modal-btn button {
    color: #000;
}



/* modal */

.getnotified .pr_modal__content {
    flex-direction: column;
}

button.getnotified {
    background-color: #75b6d0;
    padding: 10px 14px 10px 12px;
    border-radius: 200px;
    font-size: 14px;
    font-weight: 500;
    gap: 8px;
    display: flex;

}

@include breakpoint ($mobile-lg) {

    button.getnotified {
        padding: 10px 12px !important;
    }
    
    button.getnotified span {
        display: none;
    }
}

button.getnotified:hover {
    background-color: #75b6d0;
    opacity: 0.8;
}

.getnotified input {
    background-color: rgba(255, 255, 255, 0.07) !important;
    border: 1px solid #3c3c3c !important;
    color: #000;
    width: 100%;
    text-align: center;
    border-radius: 16px;
    padding-left: 12px;
    height: 50px;
    padding-right: 12px;
    font-size: 16px;
}

.getnotified .actions input {
    border: none !important;
    margin-top: 12px;
    border-radius: 200px;
    color: #fff;
    font-weight: 500;
    background-color: $pdpRed !important;
}

.getnotified .hs-error-msg {
    color: red;
}

/* search overrides */

.search-results .product-tile-details, .search-results .product-tile-title, .search-results .product-tile-category   {
    color: #000 !important;
}

.search-results .product-tile-image {
    background-color: #37373724 !important;
}

.search-results .product-tile-image:hover {
    background-color: $pdpRed !important;
}

/* homepage hide category titles */
.flickity-slider .product-tile .product-tile-link-wrapper .product-tile-details .product-tile-info .product-tile-category  {
    display: initial !important;
}

.product-tile .product-tile-link-wrapper .product-tile-details .product-tile-info .product-tile-category  {
    display: none !important;
}

.product-tile-info {
    margin-right: 12px;
}

.products_carousel .pr_disabled__prevbtn .pr_carousel__prevbtn .pr_btn, .products_carousel .pr_disabled__nextbtn .pr_carousel__nextbtn .pr_btn {
    background-color: transparent !important;
    opacity: 0.4;
}