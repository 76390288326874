@import 'styles/_sass-includes.scss';

.product-feed{
  padding: 1.5rem 3em 0;
  max-width: 136.3rem;
  margin: 0 auto;
  width: 100%;

  @include breakpoint($mobile-lg) {
    padding: 1.5rem 1.5rem 0;
  }

  .product-feed-skeleton-container {
    display: flex;
    gap: 20px;
    justify-content: center;
    width: 100%;
    height: 350px;
    flex-wrap: wrap;

    .product-tile-skeleton {
      width: calc(25% - 1.5rem);
      height: calc(80% - 1.5rem);

      @include breakpoint($mobile-lg) {
        width: 45%;
      }
    }
  }
}

.tile-heading {
  color: var(--neutral-100);
}

.limited-edition .product-tile-image {
  position: relative;

  &:after {
    content: 'Limited Edition';
    position: absolute;
    top: 12px;
    left: 12px;
    padding: 0.5rem 1rem;
    background-color: #000;
    color: #fff;
    font-size: 1.2rem;
    font-weight: 700;
    text-transform: uppercase;
    z-index: 1;
  }
}